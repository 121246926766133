import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { useModalContext } from '@providers/ModalProvider';

import { Button } from '@components/form-elements/buttons/Button';
import { AbsoluteSpinner } from '@components/spinners/Spinner';
import { Input } from '@components/form-elements/Input';
import { setBillingAccountFixedRate } from '@queries/admin/billing-accounts/setBillingAccountFixedRate';
import { Switch } from '@components/form-elements/Switch';
import { Select } from '@components/form-elements/Select';
import { TBillingAccount } from 'app/types/entities/TBillingAccount';
import { AdminService } from '../../../API';
import { useAPI } from '@hooks/useAPI';

const ModalWrapper = styled.div`
  background: #ffffff;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  position: relative;
  padding: 30px;
  text-align: left;
  width: 400px;

  h2 {
    color: #858dbd;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 24px */
    margin: 0;
    padding: 0 0 30px;
    text-align: center;
  }

  .input-wrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .label {
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 100%;
      color: #858dbd;
      padding: 0 10px 0 0;
    }

    .input,
    .switch,
    .select,
    .textarea {
      margin: 0 0 20px;
    }

    .name {
      color: #00122d;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%; /* 24px */
      padding: 0 0 20px;
    }

    input {
      height: 30px;
    }
  }

  .card,
  .rate {
    color: #00122d;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 24px */
    padding: 0 0 20px;

    &.red {
      color: red;
    }
  }

  .description {
    color: #878787;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%; /* 15.6px */
    padding: 0 0 10px;

    p {
      padding: 0 0 2px 8px;
      margin: 0;

      &:first-child {
        padding-left: 0;
      }
    }
  }

  .buttonGroup {
    display: flex;
    gap: 10px;
    justify-content: flex-end;
    margin-top: 20px;

    button {
      width: 130px;
    }
  }
`;

type TProps = {
  billingAccountId: string;
  onCancel: () => void;
  onSuccess: () => void;
};

export const AdminBillingAccountRateModal = ({ billingAccountId, onCancel, onSuccess }: TProps) => {
  const { call } = useAPI();
  const { closeModal } = useModalContext();
  const [rate, setRate] = useState<number | string>(9);
  const [ratePlan, setRatePlan] = useState('');
  const [fixed, setFixed] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [billingAccount, setBillingAccount] = useState<TBillingAccount>();

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const billingAccount = await call(AdminService.getBillingAccount({ baId: billingAccountId }));
      setBillingAccount(billingAccount);
      setRate(Number(billingAccount.postProcessingASRRate ?? 0));
      setFixed(billingAccount.fixedRate);
      setIsLoading(false);
    })();
  }, []);

  const changeRatePlan = async (value: string) => {
    setRatePlan(value);
    if (value === 'freelancer') {
      setRate(13.5);
    }
    if (value === 'pro') {
      setRate(13.5);
    }
    if (value === 'agency') {
      setRate(12);
    }
    if (value === 'business') {
      setRate(9);
    }
  };

  const onSubmit = async () => {
    setIsLoading(true);
    await setBillingAccountFixedRate(billingAccountId, fixed, Number(rate));
    closeModal();
    onSuccess();
  };

  return (
    <ModalWrapper>
      {isLoading ? <AbsoluteSpinner overlay={true} /> : null}
      <h2>AutoScript Rate Editor</h2>

      <div className="input-wrapper">
        <div className="label">Billing Account</div>
        <div className="name">{billingAccount?.name}</div>
      </div>

      <div className="input-wrapper">
        <div className="label">Organization</div>
        <div className="name">{billingAccount?.organization?.name}</div>
      </div>

      <div className="input-wrapper">
        <div className="label">Fixed rate</div>
        <Switch className="switch" checked={fixed} onChange={() => setFixed(!fixed)} />
      </div>

      <div className="input-wrapper">
        <div className="label">Rate plan</div>
        <Select
          value={ratePlan}
          options={[
            { value: '', label: 'Starter' },
            { value: 'freelancer', label: 'Freelancer' },
            { value: 'pro', label: 'Pro' },
            { value: 'agency', label: 'Agency' },
            { value: 'business', label: 'Business' },
          ]}
          onChange={(_, value) => changeRatePlan(value)}
          label="Team"
          required
          name="role"
        />
      </div>

      <div className="input-wrapper">
        <div className="label">Price per transcription audio hour</div>
        <Input
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            const { value } = e.target;
            setRatePlan('');
            if (!value.length) {
              setRate(0);
              return;
            }
            setRate(value);
            if (value[value.length - 1] === '.') {
              setRate(value);
              return;
            }
            if (value.includes('.')) {
              const afterDotItemsCount = value.split('.')[1].length;
              if (afterDotItemsCount > 2) {
                setRate(Number(value).toFixed(2));
                return;
              }
            }
            if (Number(value)) {
              setRate(Number(value));
              return;
            }
            setRate(0);
          }}
          label="Price per transcription audio hour"
          value={rate}
        />
      </div>

      <div className="buttonGroup">
        <Button variant="outlined" onClick={onCancel}>
          Cancel
        </Button>
        <Button disabled={isLoading} onClick={onSubmit}>
          Save
        </Button>
      </div>
    </ModalWrapper>
  );
};
