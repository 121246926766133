export enum ModalNamesEnum {
  WhatsNew = 'WhatsNew',
  AdminEditSetting = 'AdminEditSetting',
  AdminAddBalanceToBillingAccount = 'AdminAddBalanceToBillingAccount',
  AdminBillingAccountRate = 'AdminBillingAccountRate',
  AdminBillingAccountASOneRate = 'AdminBillingAccountASOneRate',

  ContactSales = 'ContactSales',
  PaymentMethodsHistory = 'PaymentMethodsHistory',
  AddPaymentMethod = 'AddPaymentMethod',

  AutoTopUpSettings = 'AutoTopUpSettings',
  TopUpCreditBalance = 'TopUpCreditBalance',
  Status = 'Status',
  TransactionStatus = 'TransactionStatus',

  PriceModal = 'PriceModal',

  UnlockJob = 'UnlockJob',
  UnlockJobFull = 'UnlockJobFull',

  CreateUserModal = 'CreateUserModal',

  DeleteJobModal = 'DeleteJobModal',
  DeleteJobFromCaseModal = 'DeleteJobFromCaseModal',
  MoveJobToWSModal = 'MoveJobToWSModal',
  AddWSToTeamModal = 'AddWSToTeamModal',
  MoveJobsToWSModal = 'MoveJobsToWSModal',
  DeleteJobsModal = 'DeleteJobsModal',
  CustomConfirmationModal = 'CustomConfirmationModal',

  ResubmitJobModal = 'ResubmitJobModal',
  AdminResubmitJobModal = 'AdminResubmitJobModal',
  AdminMoveJobModal = 'AdminMoveJobModal',
  AdminRefundJobModal = 'AdminRefundJobModal',

  InviteUserModal = 'InviteUserModal',
  InviteUsersByCsvModal = 'InviteUsersByCsvModal',
  DeleteInvitationModal = 'DeleteInvitationModal',
  ResendInvitationModal = 'ResendInvitationModal',
  AdminInviteUserModal = 'AdminInviteUserModal',

  NewWorkspaceModal = 'NewWorkspaceModal',
  DeleteWorkspaceModal = 'DeleteWorkspaceModal',

  AssignJobModal = 'AssignJobModal',
  GetAssigneeModal = 'GetAssigneeModal',
  GetAssigneeWithoutCommentModal = 'GetAssigneeWithoutCommentModal',
  SearchModal = 'SearchModal',

  AddUserLicenseModal = 'AddUserLicenseModal',

  EditJobNotesModal = 'EditJobNotesModal',
  EditJobCaseModal = 'EditJobCaseModal',
  EditJobTagsModal = 'EditJobTagsModal',
  EditDeadline = 'EditDeadline',

  JobHistory = 'JobHistory',

  ImageUploadModal = 'ImageUploadModal',
  NewOrganizationModal = 'NewOrganizationModal',

  AddTeamMembersModal = 'AddTeamMembersModal',
  EditTeamMembersModal = 'EditTeamMembersModal',
  AddTeamToWorkspaceModal = 'AddTeamToWorkspaceModal',

  DeleteRepositoryModal = 'DeleteRepositoryModal',
  DeleteTeamModal = 'DeleteTeamModal',
  NewTeamModal = 'NewTeamModal',
  DeleteMemberModal = 'DeleteMemberModal',
  AssignRepositoryModal = 'AssignRepositoryModal',
  SelectCaseFiles = 'SelectCaseFiles',

  ChooseASOneSubscriptionModal = 'ChooseASOneSubscriptionModal',
  EditASOneSubscriptionModal = 'EditASOneSubscriptionModal',
  ConfirmSubscription = 'ConfirmSubscription',
  ConfirmSubscriptionCancellation = 'ConfirmSubscriptionCancellation',
  AdminConfirmSubscriptionCancellation = 'AdminConfirmSubscriptionCancellation',

  DeleteAPIKey = 'DeleteAPIKey',
  CreateAPIKey = 'CreateAPIKey',

  AddNewASOnePlan = 'AddNewASOnePlan',
  EditASOnePlan = 'EditASOnePlan',

  GenericErrorModal = 'GenericErrorModal',

  CreateNewSMSCountry = 'CreateNewSMSCountry',
  CreateNewWhitelistNumber = 'CreateNewWhitelistNumber',

  BulkJobCreationModal = 'BulkJobCreationModal',

  AddAdminTransactionModal = 'AddAdminTransactionModal',

  EditUserPhone = 'EditUserPhone',
  EditUserEmail = 'EditUserEmail',
  EditConcurrentJobsLimit = 'EditConcurrentJobsLimit',
  OpenJobVersion = 'OpenJobVersion',

  AddCourseStudent = 'AddCourseStudent',
  ChooseCloudLayout = 'ChooseCloudLayout',
}
