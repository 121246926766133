import styled from 'styled-components';

export const Wrapper = styled.main`
  padding: 8px 30px 70px;
  min-height: calc(100vh - 120px);

  .flex-block {
    display: flex;
    gap: 10px;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;

    & .left-wrapper {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
      justify-content: space-between;
      align-items: flex-start;
      width: 100%;
    }

    & .line {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
      justify-content: space-between;
      align-items: flex-start;
      width: 100%;
    }

    & .card {
      min-width: 200px;
      width: 120px;
      height: 120px;
      background: #ffffff;
      box-shadow: 0 8px 10px rgba(0, 0, 0, 0.05);
      border-radius: 10px;
      padding: 20px;
      position: relative;
      flex: 1 0 auto;

      & .title {
        color: #858dbd;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 150%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      & .superTitle {
        color: #40608f;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      & .subTitle {
        color: #7cb1fe;
        font-style: normal;
        font-weight: 600;
        font-size: 30px;
        line-height: 47px;
        margin-top: -2px;
      }

      & .description {
        color: #858dbd;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 150%;
      }
    }
  }
`;
